/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../../pages/dashboard/dashboard';
import Header from '../header/header';
import treeViewSlice from '../../redux/slices/treeView.slice';
import { RootState } from '../../redux/store/rootReducer';
import closeIcon from '../../assets/images/icons/close-icon.svg';

const LayoutDashboard = () => {
  const dispatch = useDispatch();
  const keys = useSelector((state: RootState) => state?.treeView?.keys);
  const keysPopUp = useSelector((state: RootState) => state?.treeView?.keysPopUp);
  const setKeysPopup = () => {
    dispatch(treeViewSlice.actions.setKeysPopUp(false));
  };
  return (
    <div>
      <Header container="fluid" />
      <div className="w-full h-screen pt-10">
        {keysPopUp && (

        <div>
          <div
            className={`popup-wrapper fixed w-full h-full top-0 left-0 overflow-auto ${
              keysPopUp ? 'block' : 'hidden'
            }`}
          >
            <div className="popup-container absolute w-full mb-6 top-6 md:mb-0 md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2">
              <div className="w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden">
                <div
                  onClick={() => setKeysPopup()}
                  className="popup-close absolute top-6 right-5 cursor-pointer"
                >
                  <img src={closeIcon} />
                </div>
                <div className="popup-title font-bold text-2xl">Device Keys</div>
                <div className="popup-frm w-full float-left pt-4 pb-2">
                  <div className="flex flex-col mb-3">
                    <label className="font-medium text-sm">Security key 01</label>
                    <input
                      className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Enter Email"
                      type="text"
                      value={keys.security_key_01}
                    />

                  </div>

                  <div className="flex flex-col mb-3">
                    <label className="font-medium text-sm">Security key 02</label>
                    <input
                      className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Enter Name"
                      type="text"
                      value={keys.security_key_02}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        )}
        <Dashboard />
      </div>
    </div>

  );
};

export default LayoutDashboard;
