/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import './login.scss';
import LoginLayout from '../../components/layout/loginLayout';
import AuthLayout from '../../components/layout/layoutAuth';
import authSlice from '../../redux/slices/auth.slice';
import { loginFormSchema } from '../../services/validation';

const initialValues = {
  email: '',
  password: '',
};

const Login = () => {
  const formikRef: any = useRef();
  const dispatch = useDispatch();
  const onSubmit = (values: typeof initialValues) => {
    dispatch(authSlice.actions.login({ password: values.password, username: values.email }));
  };
  const [pwdVisibility, setPwdVisibility] = useState(false);
  return (
    <AuthLayout>
      <LoginLayout>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={loginFormSchema}
        >
          {({
            handleSubmit, values, handleChange, handleBlur,
          }) => (
            <div className="py-12 px-2 mx-auto max-w-sm w-full login-frm">
              <p className="text-2xl md:text-3xl font-bold text-black mb-6">
                Sign in with your
                {' '}
                <br />
                i2open credentials
              </p>
              <form className="frm-container w-full">
                <div className="flex flex-col mb-4">
                  <label className="font-medium text-black mb-1">Email</label>
                  <div className="w-full relative">
                    <input
                      name="email"
                      className="w-full text-sm px-4 pl-11 h-14 border-gray border border-gray-300 rounded-xl focus:outline-none placeholder-gray-300"
                      placeholder="Enter your Email"
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="absolute w-11 h-full top-0 left-0 email-icon" />
                  </div>
                </div>
                <div className="flex flex-col mb-5">
                  <label className="font-medium text-black mb-1">Password</label>
                  <div className="w-full relative">
                    <input
                      className="w-full text-sm px-11 h-14 border-gray border border-gray-300 rounded-xl focus:outline-none placeholder-gray-300"
                      placeholder="Your password"
                      name="password"
                      type={!pwdVisibility ? 'password' : 'text'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          handleSubmit();
                        }
                      }}
                      value={values.password}
                    />
                    <div className="absolute w-11 h-full top-0 left-0 pwd-icon" />
                    <div
                      onClick={() => {
                        setPwdVisibility(!pwdVisibility);
                      }}
                      className={`visible-icon absolute h-full top-0 right-0 ${pwdVisibility ? 'active-icon' : ''}`}
                    />
                  </div>
                </div>
                <div className="flex button-wrapper">
                  {/* <button
                  type="button"
                  className="mr-2 rounded-3xl border text-primary border-primary text-white h-10 focus:outline-none mt-2 flex-1 text-sm"
                >
                  Regsiter
                </button> */}
                  <button
                    type="button"
                    className="ml-2 rounded-3xl bg-primary text-white h-10 focus:outline-none mt-2 text-sm flex-1"
                    onClick={() => {
                      if (values.email && values.password) handleSubmit();
                    }}
                  >
                    Login
                  </button>
                </div>
                <div className="flex justify-end">
                  <a className="font-normal text-xs mt-6 text-black underline">Forgot Password?</a>
                </div>
              </form>
            </div>
          )}
        </Formik>
      </LoginLayout>
    </AuthLayout>
  );
};

export default Login;
