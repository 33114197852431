/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint no-unused-vars: "error" */

import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  treeView:
    | any
    | {
        locationName: string;
        type: string;
        building: any;
      }
    | undefined;
  locationByIdResponse: any;
  buildingByIdResponse: any;
  floorByIdResponse: any;
  departmentByIdResponse: any;
  doorByIdResponse: any;
  getAllDevicesInDoor:any
  keys:any
  keysPopUp:Boolean
  doorLogs:any
  };

const initialState: InitialState = {
  treeView: [],
  locationByIdResponse: [],
  buildingByIdResponse: [],
  floorByIdResponse: [],
  departmentByIdResponse: [],
  getAllDevicesInDoor: [],
  doorByIdResponse: [],
  keys: {},
  keysPopUp: false,
  doorLogs: [],
  // authenticated: false,
  // initialAuthentcationRequest: false,
  // token: '',
};
export const getDoorLogs = (state: InitialState, action: any) => {
};
export const setDoorLogs = (state: InitialState, action: any) => {
  state.doorLogs = action.payload;
};
export const setKeys = (state: InitialState, action: any) => {
  state.keys = action.payload;
};
export const setKeysPopUp = (state: InitialState, action: any) => {
  state.keysPopUp = action.payload;
};
export const getLocationById = (state: InitialState, action: any) => {
};
export const setLocationById = (state: InitialState, action: any) => {
  state.locationByIdResponse = action.payload;
};
export const getBuildingById = (state: InitialState, action: any) => {
};
export const setBuildingById = (state: InitialState, action: any) => {
  state.buildingByIdResponse = action.payload;
};
export const getFloorById = (state: InitialState, action: any) => {
};
export const setFloorById = (state: InitialState, action: any) => {
  state.floorByIdResponse = action.payload;
};
export const getDepartmentById = (state: InitialState, action: any) => {
};
export const setDepartmentById = (state: InitialState, action: any) => {
  state.departmentByIdResponse = action.payload;
};
export const getDoorById = (state: InitialState, action: any) => {
};
export const setDoorById = (state: InitialState, action: any) => {
  state.doorByIdResponse = action.payload;
};
export const setAddClientTree = (state: InitialState, action: any) => {
  const client = action.payload.map((item: any) => {
    return { ...item, type: 'client', children: [] };
  });
  state.treeView = client;
};
export const setAddLocationTree = (state: InitialState, action: any) => {
  const location = action.payload.response.map((item: any) => {
    return { ...item, type: 'location', children: [] };
  });
  state.treeView[action.payload.index].children = location;
};
export const getLocationByClientTree = (state: any, action: any) => state;

export const getAllClients = (state: any, action: any) => state;

export const getBuildingByLocationTree = (state: any, action: any) => state;

export const setAddBuildingByLocationTree = (
  state: InitialState,
  action: any,
) => {
  const building = action.payload.response.map((item: any) => {
    return { ...item, type: 'building', children: [] };
  });
  state.treeView[action.payload.index].children[action.payload.locationIndex].children = building;
};
export const getDoorByDepartment = (state: any, action: any) => state;
export const getDepartmentByFloor = (state: any, action: any) => state;
export const getFloorByBuilding = (state: any, action: any) => state;

export const setAddDoorTree = (state: InitialState, action: any) => {
  const door = action.payload.response.map((item: any) => {
    return { ...item, type: 'door', children: [] };
  });
  state.treeView[action.payload.index].children[action.payload.locationIndex].children[
    action.payload.buildingIndex
  ].children[
    action.payload.floorIndex
  ].children[
    action.payload.departmentIndex
  ].children = door;
};
export const setAddFloorTree = (state: InitialState, action: any) => {
  const floor = action.payload.response.map((item: any) => {
    return { ...item, type: 'floor', children: [] };
  });
  state.treeView[action.payload.index].children[action.payload.locationIndex].children[
    action.payload.buildingIndex
  ].children = floor;
};
export const setAddDepartmentTree = (state: InitialState, action: any) => {
  const department = action.payload.response.map((item: any) => {
    return { ...item, type: 'department', children: [] };
  });
  state.treeView[action.payload.index].children[action.payload.locationIndex].children[
    action.payload.buildingIndex
  ].children[
    action.payload.floorIndex
  ].children = department;
};
export const getDeviceByLocationTree = (state: any, action: any) => state;

export const setAddDeviceTree = (state: InitialState, action: any) => {
  const device = action.payload.response.map((item: any) => {
    return { ...item, type: 'device' };
  });
  state.treeView[action.payload.index].children[action.payload.locationIndex].children[
    action.payload.buildingIndex
  ].children[
    action.payload.floorIndex
  ].children[
    action.payload.departmentIndex
  ].children[action.payload.doorIndex].children = device;
  state.getAllDevicesInDoor = device;
};

const treeViewSlice = createSlice({
  name: 'treeView',
  initialState,
  reducers: {
    setAddLocationTree,
    getBuildingByLocationTree,
    setAddBuildingByLocationTree,
    setAddDoorTree,
    getDeviceByLocationTree,
    setAddDeviceTree,
    getLocationByClientTree,
    getAllClients,
    setAddClientTree,
    getLocationById,
    setLocationById,
    getBuildingById,
    setBuildingById,
    getFloorById,
    setFloorById,
    getDepartmentById,
    setDepartmentById,
    getDoorById,
    setDoorById,
    setKeys,
    setKeysPopUp,
    setDoorLogs,
    getDoorLogs,
    getDoorByDepartment,
    getDepartmentByFloor,
    getFloorByBuilding,
    setAddFloorTree,
    setAddDepartmentTree,
  },
});

export default treeViewSlice;
