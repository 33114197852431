/* eslint-disable camelcase */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable comma-spacing */
/* eslint-disable no-undef */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './users.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import verticalDotIcon from '../../assets/images/icons/vertical-dot.svg';
import AddUserModal from '../../components/modals/users/add-user/add-user-modal';
// import AssignDoorModal from '../../components/modals/users/assign-door/assign-door-modal';
// import UnassignedDoorModal from '../../components/modals/users/unassigned-doors/unassigned-doors';
import userListSlice from '../../redux/slices/adminUsers.slice';
import { RootState } from '../../redux/store/rootReducer';

const Users = () => {
  const [openPop, setOpenPop] = useState(false);
  const [dropdownActionID, setDropdownActionID] = useState(null);
  const container = createRef<HTMLDivElement>();
  const filterData = {
    limit: 10,
    pageNo: 1,
    search: '',
  };
  const dispatch = useDispatch();

  const userList = useSelector((state: RootState) => state.userListSlice.user_lists);
  useEffect(() => {
    dispatch(userListSlice.actions.getAllAdminUsers(filterData));
  },[]);
  useEffect(() => {
    const handleOutsideClick = (event:any) => {
      if (!container?.current?.contains(event.target)) {
        if (dropdownActionID !== null) setDropdownActionID(null);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [dropdownActionID,container]);

  const dropChangeAction = (id: any) => {
    if (dropdownActionID !== id) {
      setDropdownActionID(id);
    } else {
      setDropdownActionID(null);
    }
  };

  const handleRemoveUser = (user_id: any) => {
    dispatch(userListSlice.actions.removeUser({ user_id }));
  };
  const handleResendInvite = (user_id: any) => {
    dispatch(userListSlice.actions.resendInvite({ admin_user_id: user_id }));
  };
  return (
    <div>
      <div className="flex flex-col pt-6">
        <div className="w-full flex justify-between">
          <div className="font-bold text-2xl text-black pb-2">My Users</div>
          <div className="bottom-mob-btn p-4 md:px-0">
            <button
              onClick={() => setOpenPop(true)}
              className="btn-primary-bg focus:outline-none rounded-3xl px-12 md:px-6 h-12 md:h-10 text-white text-base font-medium"
            >
              ADD USER
            </button>
          </div>
        </div>
        <div className="users-table-wrapper mt-4 mb-20">
          <div className="hidden md:flex user-table-title mb-2">
            <div className="w-2/5 text-xs font-medium pl-4">Users</div>
            <div className="w-3/5 text-xs font-medium">Role</div>
          </div>
          <div className="users-table-content">
            <>
              {userList.length > 0 ? (
                userList.map((user: any, index: number) => (
                  <div
                    key={user.personnel_id}
                    className="flex md:items-center bg-white rounded-lg px-4 py-3 shadow-sm mb-2 flex-col md:flex-row relative"
                  >
                    <div className="w-full md:w-2/5 flex items-center order-0 md:order-0">
                      <div className="w-10 h-10 flex-shrink-0 mr-2 bg-primary rounded-full flex text-white items-center justify-center">
                        {user.name.substring(0, 2)}
                      </div>
                      <div className="user-info overflow-hidden pr-2">
                        <div className="font-medium text-sm relative truncate -mt-1 pr-2">{user.name}</div>
                        <span className="block truncate leading-none pr-2">{user.email}</span>
                      </div>
                    </div>
                    <div className="flex-grow pl-12 pt-2 md:pt-0 md:pr-6 md:pl-0">
                      <div className="flex flex-col w-full role-list float-left">
                      <span className="block truncate leading-none text-sm font-medium pr-2">{user.role}</span>

                      </div>
                    </div>
                    <div className="w-10 absolute top-4 right-0 md:static">
                      <div className="relative w-full" ref={container}>
                        <img
                          onClick={() => {
                            dropChangeAction(index);
                          }}
                          className="px-2 mx-auto cursor-pointer"
                          src={verticalDotIcon}
                          alt="Actions"
                        />
                        {dropdownActionID === index && (
                          <ul className="absolute mb-2 top-6 right-4 bg-white shadow-md border dropdown-list py-1 z-10">
                            <li onClick={() => handleResendInvite(user.id)} className="text-xs px-3 py-1">Resend Invite</li>
                            <li onClick={() => handleRemoveUser(user.id)} className="text-xs px-3 py-1">
                              Remove User
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full flex justify-center items-center py-5 h-40 text-gray-110 font-normal bg-white border-t" />
              )}
            </>
          </div>
        </div>
      </div>
      <AddUserModal hasModalView={openPop} closeModal={() => setOpenPop(false)} />
    </div>
  );
};

export default Users;
