import React from 'react';
import { Line } from 'react-chartjs-2';

const DoorChart = ({ data }:any) => {
  let dates = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  let doorOpening = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  if (data.length) { dates = data.map((obj:any) => new Date(obj.date).toDateString()); }
  if (data.length) { doorOpening = data.map((obj:any) => obj.door_count); }

  const graphData = {
    labels: dates,
    datasets: [
      {
        label: 'Door Openings',
        backgroundColor: 'rgba(0, 164, 159, 0.34)',
        borderColor: 'transparent',
        borderWidth: 0,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        pointBackgroundColor: '#00A49F',
        pointBorderColor: '#fff',
        data: doorOpening,
      },
    ],
  };

  return (
    <div>
      <Line
        data={graphData}
        width={400}
        height={190}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontSize: 12,
                  fontColor: '#6F6C99',
                  padding: 10,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [{
              ticks: {
                fontSize: 12,
                fontColor: '#6F6C99',
              },
              gridLines: {
                display: false,
              },
            },
            ],
          },
        }}
      />
    </div>
  );
};

export default DoorChart;
