/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const getAllUnattachedDevices = (state: any, action: any) => state;
const getAllAttachedDevices = (state: any, action: any) => state;
const setAttachedDeviceList = (state: any, action: any) => {
  state.attached_device_lists = action.payload;
};
const setUnattachedDeviceList = (state: any, action: any) => {
  state.unattached_device_lists = action.payload;
};

const setAttachedDeviceListCount = (state: any, action: any) => {
  state.attached_device_list_count = action.payload;
};

const setUnattachedDeviceListCount = (state: any, action: any) => {
  state.unattached_device_list_count = action.payload;
};

const attachDevice = (state: any, action: any) => state;

const removeUnattachedDevices = (state: any, action: any) => state;

const createDevice = (state: any, action: any) => state;

const initialState = {
  attached_device_lists: [],
  unattached_device_lists: [],
  attached_device_list_count: '',
  unattached_device_list_count: '',
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    attachDevice,
    getAllUnattachedDevices,
    getAllAttachedDevices,
    setAttachedDeviceList,
    setUnattachedDeviceList,
    setAttachedDeviceListCount,
    setUnattachedDeviceListCount,
    createDevice,
    removeUnattachedDevices,
  },
});

export default deviceSlice;
