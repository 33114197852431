/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Header from '../header/header';
import closeIcon from '../../assets/images/icons/close-icon.svg';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */

// import Clients from '../../pages/clients/clients';
// import Dashboard from '../../pages/dashboard/dashboard';
import Users from '../../pages/users/users';
import Location from '../../pages/location/location';
// import { RootState } from '../../redux/store/rootReducer';
import treeViewSlice from '../../redux/slices/treeView.slice';
import { RootState } from '../../redux/store/rootReducer';
import Devices from '../../pages/devices/devices';

const Layout = () => {
  const dispatch = useDispatch();
  const keys = useSelector((state: RootState) => state?.treeView?.keys);
  const keysPopUp = useSelector((state: RootState) => state?.treeView?.keysPopUp);
  const setKeysPopup = () => {
    dispatch(treeViewSlice.actions.setKeysPopUp(false));
  };
  // useEffect(() => {
  //   dispatch(authSlice.actions.getVersion());
  //   const localversion = localStorage.getItem('webVersion') || '';
  //   if (version !== '') {
  //     if (localversion === '') {
  //       localStorage.setItem('webVersion', version);
  //     } else if (localversion !== version) {
  //       setconfirmpopup(true);
  //     }
  //   }
  // }, [navigator.history.location, version]);

  return (
    <div>
      <Header />
      <div className="w-full min-h-screen pt-16">
        {keysPopUp && (

          <div>
            <div
              className={`popup-wrapper fixed w-full h-full top-0 left-0 overflow-auto ${
                keysPopUp ? 'block' : 'hidden'
              }`}
            >
              <div className="popup-container absolute w-full mb-6 top-6 md:mb-0 md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2">
                <div className="w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden">
                  <div
                    onClick={() => setKeysPopup()}
                    className="popup-close absolute top-6 right-5 cursor-pointer"
                  >
                    <img src={closeIcon} />
                  </div>
                  <div className="popup-title font-bold text-2xl">Device Keys</div>
                  <div className="popup-frm w-full float-left pt-4 pb-2">
                    <div className="flex flex-col mb-3">
                      <label className="font-medium text-sm">Security key 01</label>
                      <input
                        className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                        placeholder="Enter Email"
                        type="text"
                        value={keys.security_key_01}
                      />

                    </div>

                    <div className="flex flex-col mb-3">
                      <label className="font-medium text-sm">Security key 02</label>
                      <input
                        className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                        placeholder="Enter Name"
                        type="text"
                        value={keys.security_key_02}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <Switch>
            <Route path="/dashboard/clients">
              <Location />
            </Route>

            <Route path="/dashboard/users">
              <Users />
            </Route>

            <Route path="/dashboard/devices">
              <Devices />
            </Route>

            <Route path="/dashboard/location/:locationId" component={Location} />
            <Route path="/dashboard/building/:buildingId" component={Location} />
            <Route path="/dashboard/floor/:floorId" component={Location} />
            <Route path="/dashboard/department/:departmentId" component={Location} />
            <Route path="/dashboard/door/:doorId" component={Location} />
          </Switch>

        </div>
      </div>
    </div>
  );
};
export default Layout;
