/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import logo from '../../assets/images/icons/logo.svg';
import './layout.scss';

const LayoutAuth = ({ children }: any) => (
  <div>
    <div className="header fixed w-full">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <a className="flex items-center h-16 text-black text-lg font-bold flex-shrink-0 whitespace-nowrap">
          <img className="mr-2" src={logo} alt="logo" />
          {' '}
          i2open
        </a>
      </div>
    </div>
    <div className="w-full h-screen">
      {children}
    </div>
  </div>

);

export default LayoutAuth;
