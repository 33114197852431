/* eslint-disable import/no-unresolved */
import { combineReducers } from 'redux';
import loaderSlice from '../slices/loader.slice';
import authSlice from '../slices/auth.slice';
import clientListSlice from '../slices/client.slice';
import treeViewSlice from '../slices/treeView.slice';
import userListSlice from '../slices/adminUsers.slice';
import dashboardSlice from '../slices/dashboard.slice';
import device from '../slices/device.slice';

const rootReducer = combineReducers({
  loader: loaderSlice.reducer,
  auth: authSlice.reducer,
  client: clientListSlice.reducer,
  treeView: treeViewSlice.reducer,
  userListSlice: userListSlice.reducer,
  dashboardSlice: dashboardSlice.reducer,
  device: device.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
