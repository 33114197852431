/* eslint-disable camelcase */
import { takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import loaderSlice from '../slices/loader.slice';
import Api from '../../services/apis/index';
import deviceListSlice from '../slices/device.slice';

// eslint-disable-next-line require-yield
function* handleError(err: any) {
  if (err?.response) {
    if (err?.response?.status && err?.response?.data?.msg && err?.response?.data?.error) {
      toast.error(err?.response?.data?.msg);
    }
  } else if (err.message) {
    toast.error(err?.response?.data?.msg);
  }
}
function* attachDevice(action: any) {
  try {
    const {
      // eslint-disable-next-line camelcase
      id,
      client_id,
    } = action.payload;
    let args = {};
    args = {
      id,
      client_id,
    };
    const demo = {
      limit: '',
      pageNo: '',
      search: '',
    };
    yield put(loaderSlice.actions.show());
    yield Api.device.attachDevice(args);
    yield put(loaderSlice.actions.hide());
    toast.success('device attached to client successfully');
    // const values: any = action.payload;
    // const response = yield Api.device.getAllUnattachedDevices(values);
    yield put(deviceListSlice.actions.getAllUnattachedDevices(demo));
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* getAllAttachedDevices(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.device.getAllAttachedDevices(values);
    yield put(deviceListSlice.actions.setAttachedDeviceList(response.data.data.data));
    yield put(deviceListSlice.actions.setAttachedDeviceListCount(response.data.data.totalCount));
    yield put(loaderSlice.actions.hide());
    // toast.success('aquired attached devices successfully');
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* getAllUnAttachedDevices(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.device.getAllUnattachedDevices(values);
    yield put(deviceListSlice.actions.setUnattachedDeviceList(response.data.data.data));
    yield put(deviceListSlice.actions.setUnattachedDeviceListCount(response.data.data.totalCount));
    yield put(loaderSlice.actions.hide());
    // toast.success('aquired unattached devices successfully');
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* removeUnattachedDevices(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const demo = {
      limit: '',
      pageNo: '',
      search: '',
    };
    const values: any = action.payload;
    yield Api.device.removeUnattachedDevices(values);
    yield put(loaderSlice.actions.hide());
    toast.success('device removed successfully');
    yield put(deviceListSlice.actions.getAllUnattachedDevices(demo));
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* createDevice(action: any) {
  try {
    const {
      // eslint-disable-next-line camelcase
      device_id,
    } = action.payload;
    let args = {};
    args = {
      device_id,
    };
    const demo = {
      limit: '',
      pageNo: '',
      search: '',
    };
    yield put(loaderSlice.actions.show());
    yield Api.device.createDevice(args);
    yield put(loaderSlice.actions.hide());
    toast.success('device created successfully');
    yield put(deviceListSlice.actions.getAllUnattachedDevices(demo));
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* deviceSaga() {
  yield takeEvery('device/attachDevice', attachDevice);
  yield takeEvery('device/getAllAttachedDevices', getAllAttachedDevices);
  yield takeEvery('device/getAllUnattachedDevices', getAllUnAttachedDevices);
  yield takeEvery('device/createDevice', createDevice);
  yield takeEvery('device/removeUnattachedDevices', removeUnattachedDevices);
}

export default deviceSaga;
