/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './dashboard.scss';
import Treeview from '../../components/treeview/treeview';
import groupIcon from '../../assets/images/icons/group.svg';
import doorConnectedIcon from '../../assets/images/icons/door-connected.svg';
import deviceIcon from '../../assets/images/icons/devices.svg';
import notificationIcon from '../../assets/images/icons/notification-icon.svg';
import { RootState } from '../../redux/store/rootReducer';
import dashboardSlice from '../../redux/slices/dashboard.slice';
import DoorChart from '../../components/chart/door-chart';

const Dashboard = () => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];
  const dispatch = useDispatch();
  const dashboardInfo = useSelector((state: RootState) => state.dashboardSlice.dashboardInfo);
  const dashboardGraphData = useSelector((state: RootState) => state.dashboardSlice.dashboardGraphData);
  // const [daySelectDrop, setDaySelectDrop] = useState(false);
  const [graphDays, setgraphDays] = useState(7);

  const handleSelect = (e: any) => {
    setgraphDays(e.target.value);
    dispatch(dashboardSlice.actions.getDashboardGraphData({ limit: e.target.value }));
  };

  useEffect(() => {
    dispatch(dashboardSlice.actions.getDashboardGraphData({ limit: 7 }));
    dispatch(dashboardSlice.actions.getDashboardInfo());
  }, []);
  return (
    <div className="flex flex-col md:flex-row pt-6 min-h-full relative">
      <div className="w-full static mt-4 md:mt-0 md:absolute w-full px-2 sm:px-6 lg:px-8 left-0 top-12">
        <div className="welcome-screen p-4 rounded-lg">
          <h2 className="text-black font-bold leading-normal">
            Welcome to the home page
          </h2>
          <span className="font-medium relative -top-1">
            {`${new Date().getDate()} ${monthNames[new Date().getMonth()]}  ${new Date().getFullYear()}`}
          </span>
        </div>
      </div>
      <div className="sidebar-wrapper min-h-full pt-4 md:pt-32 px-2 sm:px-6 md:px-0 md:pl-6 lg:pl-8 w-full md:w-96 flex-shrink-0">
        <div className="sidebar flex-shrink-0 px-4 md:px-0 rounded-md md:rounded-0">
          <Treeview bg={false} name="Clients" />
        </div>
      </div>
      <div className="px-2 sm:px-6 md:px-0 md:pl-4 md:pr-6 lg:pr-8 flex-grow md:pt-32">
        <div className="w-full float-left md:mt-4 flex flex-col lg:block">
          <div className="w-full lg:w-2/5 float-left md:pr-2 order-1 lg:order-0 my-3 md:my-5 lg:my-0">
            <div className="flex w-full justify-between rounded-lg items-center px-4 py-3 mb-3 info-box">
              <div className="uppercase font-medium text-sm overflow-hidden flex items-center">
                <img className="mr-2" src={groupIcon} />
                <div className="truncate text-color-dark">Clients</div>
              </div>
              <div className="text-xl font-medium bg-yellow-100 px-3 py-2 ml-2 rounded">
                {dashboardInfo.clientsCount}
              </div>
            </div>
            <div className="flex w-full justify-between rounded-lg items-center px-4 py-3 mb-3 info-box">
              <div className="uppercase font-medium text-sm overflow-hidden flex items-center">
                <img className="mr-2" src={doorConnectedIcon} />
                <div className="truncate text-color-dark">Doors Connected</div>
              </div>
              <div className="text-xl font-medium bg-blue-100 px-3 py-2 ml-2 rounded">
                {dashboardInfo.doorCount}

              </div>
            </div>
            <div className="flex w-full justify-between rounded-lg items-center px-4 py-3 mb-3 info-box">
              <div className="uppercase font-medium text-sm overflow-hidden flex items-center">
                <img className="mr-2" src={deviceIcon} />
                <div className="truncate text-color-dark">Unassigned devices</div>
              </div>
              <div className="text-xl font-medium bg-blue-50 px-3 py-2 ml-2 rounded">
                {dashboardInfo.unassignedDoorCount}
              </div>
            </div>
            <div className="flex w-full justify-between rounded-lg items-center px-4 py-3 mb-3 info-box">
              <div className="uppercase font-medium text-sm overflow-hidden flex items-center">
                <img className="mr-2" src={notificationIcon} />
                <div className="truncate text-color-dark">Buildings</div>
              </div>
              <div className="text-xl font-medium bg-pink-100 px-3 py-2 ml-2 rounded">
                {dashboardInfo.buildingCount}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-3/5 float-left lg:pl-2 order-0 lg:order-1 my-3 md:my-0">
            <div className="flex flex-col graph-wrapper">
              <div className="flex items-center w-full justify-between md:justify-start">
                <div className="uppercase font-medium text-sm graph-title mr-3">
                  Door Openings
                </div>
                <div className="day-select-list relative cursor-pointer">
                  <select
                    value={graphDays}
                    onChange={handleSelect}
                    className="bg-white py-2 pl-3 relative pr-8 data-selected-val text-xs font-medium"
                  >
                    <option
                      className="w-full bg-white absolute left-0 py-2 shadow-md border-t dropdown-list "
                      value="7"
                    >
                      Last 7 Days

                    </option>
                    <option
                      className="w-full bg-white absolute left-0 py-2 shadow-md border-t dropdown-list "
                      value="30"
                    >
                      Last 30 Days

                    </option>
                    <option
                      className="w-full bg-white absolute left-0 py-2 shadow-md border-t dropdown-list "
                      value="90"
                    >
                      Last 90 Days

                    </option>
                  </select>
                  {/* <div
                    onClick={() => setDaySelectDrop(!daySelectDrop)}
                    className="bg-white py-2 pl-3 relative pr-8 data-selected-val text-xs font-medium"
                  >
                    Last 7 Days
                  </div> */}
                  {/* <ul
                    className={`w-full bg-white absolute left-0 py-2 shadow-md border-t dropdown-list ${
                      daySelectDrop ? '' : 'hidden'
                    }`}
                  >
                    <li className="text-xs px-2 py-1">Last 7 Days</li>
                    <li className="text-xs px-2 py-1">Last 30 Days</li>
                    <li className="text-xs px-2 py-1">Last 90 Days</li>
                  </ul> */}
                </div>
              </div>
              <div className="w-full float-left rounded-lg mt-3 px-3 pb-3 pt-6 graph-container">
                <DoorChart data={dashboardGraphData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Dashboard;
