/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/request';
// import { any } from 'prop-types';

function* getAllLocationsByClient(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getAllClientLocations`, {
    params: {
      id: values,
    },
  });
}
function* getLocationById(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getClientLocationById`, {
    params: {
      locationId: values,
    },
  });
}
const location = {
  getAllLocationsByClient,
  getLocationById,
};

export default location;
