/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/request';
// import { any } from 'prop-types';

function* getAllBuildingsInLocation(values: any) {
  return yield request.get(
    `${APP_ENTRYPOINT}/admin/getAllBuildingsInLocation`,
    {
      params: {
        locationId: values,
      },
    },
  );
}
function* getBuildingById(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getClientBuildingById`, {
    params: {
      buildingId: values.buildingId,
      limitUnassigned: values.limitUnassigned,
    },
  });
}
const building = {
  getAllBuildingsInLocation,
  getBuildingById,
};

export default building;
