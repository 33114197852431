/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React, {
  useState, useRef, createRef, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLIENT_APP_URL } from '../../config/config';

import './clients.scss';
import Treeview from '../../components/treeview/treeview';
import ClientPopup from '../../components/modals/create-clients/client-popup';
import AssignPopup from '../../components/modals/assign-device/assign-device';
import firstIcon from '../../assets/images/icons/first-icon.svg';
import prevIcon from '../../assets/images/icons/prev-icon.svg';
import lastIcon from '../../assets/images/icons/last-icon.svg';
import nextIcon from '../../assets/images/icons/next-icon.svg';
import clientSlice from '../../redux/slices/client.slice';
import { RootState } from '../../redux/store/rootReducer';

const Clients = (props: any) => {
  const [openPop, setOpenPop] = useState(false);
  // const [openPopSecond, setOpenPopSecond] = useState(false);
  const [dropdownID, setDropdownID] = useState(null);
  const [token, setToken] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [pageNum, setpageNum] = useState(1);

  const getClientList = useSelector((state: RootState) => state.client.client_lists);
  const getClientCount: any = useSelector((state: RootState) => state.client.client_list_count);
  const getClientToken: any = useSelector((state: RootState) => state.client.client_token);

  const dispatch = useDispatch();
  let filterData = {
    limit: 10,
    pageNo: 1,
  };
  useEffect(() => {
    filterData = {
      limit: 10,
      pageNo: pageNum,
    };
    dispatch(clientSlice.actions.getAllClients(filterData));
  }, [pageNum]);

  const pagesCount = Math.ceil(getClientCount / filterData.limit);

  const PageNums = [];
  for (let i = 0; i < pagesCount; i += 1) {
    PageNums.push(i);
  }
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (getClientToken) {
      // window.location.href = `${CLIENT_APP_URL}?token=${getClientToken}&adminUser=Mortan&clientUser=${clientName}`;
      window.open(`${CLIENT_APP_URL}?token=${getClientToken}&adminUser=Mortan&clientUser=${clientName}`);
    }
  }, [getClientToken]);

  const getToken = (clientId: any, client: any) => {
    const value = {
      client_id: clientId,
    };
    dispatch(clientSlice.actions.logInAsClient(value));
    setToken(getClientToken);
    setClientName(client);
  };

  const dropChange = (id: any) => {
    if (dropdownID !== id) {
      setDropdownID(id);
    } else {
      setDropdownID(null);
    }
  };

  // const dropContainer = useRef<HTMLDivElement>(null);
  const dropContainerAssign = createRef<HTMLDivElement>();

  useEffect(() => {
    const handleOutsideClick = (event:any) => {
      if (!dropContainerAssign?.current?.contains(event.target)) {
        if (dropdownID === null) return;
        setDropdownID(null);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [dropdownID, dropContainerAssign]);

  return (

    <div className="w-full float-left">
      <div className="w-full float-left text-right">
        <div className="bottom-mob-btn p-4 md:pt-0 md:px-0">
          <button onClick={(e) => setOpenPop(true)} className="btn-primary-bg focus:outline-none rounded-3xl px-12 md:px-6 h-12 md:h-10 text-white text-base font-medium">ADD CLIENT</button>
        </div>
      </div>
      <div className="device-table-wrapper w-full float-left overflow-auto rounded-xl">
        <table className="w-full">
          <thead className="font-medium text-left">
            <th>Client ID</th>
            <th>Action</th>
          </thead>
          <tbody>
            {getClientList && getClientList.map((item:any, index:any) => (
              <tr>
                <td key={item.id}>{item.admin_name}</td>
                <td>
                  <div ref={dropContainerAssign} className="device-action-drop relative">
                    <div
                      onClick={() => {
                        dropChange(index);
                      }}
                      className="device-action-drop-title font-normal text-sm"
                    >
                      Select Action
                    </div>
                    <ul className={`action-dropdown-ul w-full py-1 absolute right-0 bg-white border border-gray-200 shadow-md text-xs font-medium cursor-pointer ${dropdownID === index ? '' : 'hidden'}`}>
                      <li
                        onClick={(event) => {
                          getToken(item.id, item.admin_name);
                          // if (getClientToken) {
                          //   window.location.href = `${CLIENT_APP_URL}?token="${getClientToken}"&adminUser="Mortan"&clientUser="${item.admin_name}"`;
                          // }
                        }}
                        className="px-2 py-1"
                      >
                        Login To Account
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="w-full py-3 float-left pagination-wrappper flex justify-center">
        <ul className="flex font-normal items-center">
          <li className="has-bg">
            <a onClick={() => { setpageNum(1); }} href="#"><img src={firstIcon} alt="First" /></a>
          </li>
          <li className="has-bg">
            <a onClick={() => { setpageNum(pageNum - 1); }} href="#"><img src={prevIcon} alt="Prev" /></a>
          </li>
          {PageNums.map((item, index) => (
            <li key={item} className="active-page">
              <a onClick={() => { setpageNum(index + 1); }} href="#">{index + 1}</a>
            </li>
          ))}
          <li className="has-bg">
            <a onClick={() => { setpageNum(pageNum + 1); }} href="#"><img src={nextIcon} alt="Next" /></a>
          </li>
          <li className="has-bg">
            <a onClick={() => { setpageNum(pagesCount); }} href="#"><img src={lastIcon} alt="Last" /></a>
          </li>
        </ul>
      </div>
      <ClientPopup hasModalView={openPop} closeModal={() => setOpenPop(false)} />
    </div>
  );
};

export default Clients;
