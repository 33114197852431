/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/request';
// import { any } from 'prop-types';

function* getDashboardData() {
  return yield request.get(`${APP_ENTRYPOINT}/admin/dashboardData`);
}
function* getDashboardGraphData(values:any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/dashboardGraphData`, {
    params: {
      ...values,
    },
  });
}

const dashboard = {
  getDashboardData,
  getDashboardGraphData,
};

export default dashboard;
