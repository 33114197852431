/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/request';
// import { any } from 'prop-types';

function* getAllDepartmentsInFloor(values: any) {
  return yield request.get(
    `${APP_ENTRYPOINT}/department/getAllDepartmentsInFloor`,
    {
      params: {
        floorId: values,
      },
    },
  );
}
function* getDepartmentById(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getClientDepartmentById`, {
    params: {
      departmentId: values,
    },
  });
}
const department = {
  getAllDepartmentsInFloor,
  getDepartmentById,
};

export default department;
