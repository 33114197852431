/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-case-declarations */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable arrow-body-style */
/* eslint-disable no-return-assign */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import clients from '../../pages/clients/clients';

import './treeview.scss';
import unionIconGreen from '../../assets/images/icons/union-green.svg';
import downArrowIcon from '../../assets/images/icons/down-arrow.svg';
import upArrowIcon from '../../assets/images/icons/up-arrow.svg';
import treeViewSlice from '../../redux/slices/treeView.slice';
import { RootState } from '../../redux/store/rootReducer';
import Client from './client';

import '../../pages/clients/clients.scss';

const Treeview = (props: any) => {
  const dispatch = useDispatch();
  const { treeView } = useSelector(
    (state: RootState) => state.treeView,
  );

  const [limitNum, setlimitNum] = useState(5);

  const handleTreeview = async (
    item: any,
    index: any,
    locationIndex:any,
    buildingIndex: any,
    floorIndex: any,
    departmentIndex: any,
    doorIndex: any,
  ) => {
    switch (item.type) {
      case 'client':
        const data = {
          id: item.id,
          type: item.type,
          index,
          locationIndex,
        };
        dispatch(treeViewSlice.actions.getLocationByClientTree(data));
        break;
      case 'location':
        const locationData = {
          id: item.id,
          type: item.type,
          index,
          locationIndex,
        };
        dispatch(treeViewSlice.actions.getBuildingByLocationTree(locationData));
        break;
      case 'building':
        const buildingData = {
          id: item.id,
          type: item.type,
          index,
          locationIndex,
          buildingIndex,
        };
        dispatch(treeViewSlice.actions.getFloorByBuilding(buildingData));
        break;

      case 'floor':
        const floorData = {
          id: item.id,
          type: item.type,
          index,
          locationIndex,
          buildingIndex,
          floorIndex,
        };
        dispatch(treeViewSlice.actions.getDepartmentByFloor(floorData));
        break;
      case 'department':
        const departmentData = {
          id: item.id,
          type: item.type,
          index,
          locationIndex,
          buildingIndex,
          floorIndex,
          departmentIndex,
        };
        dispatch(treeViewSlice.actions.getDoorByDepartment(departmentData));
        break;
      case 'door':
        const doorData = {
          id: item.id,
          type: item.type,
          index,
          locationIndex,
          buildingIndex,
          floorIndex,
          departmentIndex,
          doorIndex,
        };
        dispatch(treeViewSlice.actions.getDeviceByLocationTree(doorData));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const filterData = {
      limit: limitNum,
      pageNo: 1,
    };
    dispatch(treeViewSlice.actions.getAllClients(filterData));
  }, [limitNum]);

  const [showTree, setShowTree] = useState(false);

  return (
    <div
      className={`w-full rounded-md py-4 mb-4 ${
        props.bg ? 'bg-white px-4 custom-bg' : ''
      }`}
    >
      <div onClick={() => setShowTree(!showTree)} className="flex justify-between items-center py-1 md:py-0 md:pb-4 cursor-pointer md:cursor-default">
        <div className="font-medium text-sm tree-title uppercase">
          {props.name}
        </div>
        <div className="font-normal text-sm text-blue-500 hidden md:block">Doors</div>
        <div className="w-6 h-6 flex justify-end items-center cursor-pointer md:hidden">
          {!showTree && <img src={downArrowIcon} alt="down-arrow" /> }
          {showTree && <img src={upArrowIcon} alt="down-arrow" /> }
        </div>
      </div>
      <div className={`tree-view w-full mt-4 md:mt-0 ${showTree ? 'block' : 'hidden'} md:block`}>
        {treeView && (
          <ul>
            {treeView.map((item: any, index: any) => (
              <li key={item.id}>
                <Client
                  handleTreeview={(
                    item: any,
                    index: any,
                    locationIndex: any,
                    buildingIndex: any,
                    floorIndex: any,
                    departmentIndex: any,
                    doorIndex: any,
                  ) =>
                    handleTreeview(item, index, locationIndex, buildingIndex, floorIndex, departmentIndex, doorIndex)
                  }
                  item={item}
                  key={item.id}
                  index={index}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      <button
        type="button"
        style={{ marginTop: '15px' }}
        className="btn-primary-bg focus:outline-none rounded-3xl px-3 md:px-6 h-15 md:h-7 text-white text-base font-medium"
        onClick={() => {
          setlimitNum(limitNum * 2);
        }}> More
      </button>
    </div>
  );
};

export default Treeview;
