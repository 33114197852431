/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

export const loginFormSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export const createClientFormSchema = Yup.object().shape({
  organizationName: Yup.string().required('Required'),
  adminName: Yup.string().required('Required'),
  adminEmail: Yup.string().email().required('Required'),
});

export const createDeviceFormSchema = Yup.object().shape({
  device_id: Yup.string().required('Required'),
});

export const inviteUserFormSchema = Yup.object().shape({
  user_email: Yup.string().email().required('Required'),
  user_name: Yup.string().required('Required'),
});
